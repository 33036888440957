import bigcommerceSparkDefaults from '../bigcommerce-spark/config.js';

bigcommerceSparkDefaults.Widgets.find((w) => w.name === 'VerifyFitment').isAlwaysColumnLayout = true;
bigcommerceSparkDefaults.Widgets.find((w) => w.name === 'FacetCategoryTiles').isMultiLevel = true;

const fieldsForRelated = ['category'];
function getSelection(itemData) {
  return fieldsForRelated.flatMap((field) => {
    const value = itemData[field];
    return !value
      ? []
      : Array.isArray(value)
        ? value.map((term) => ({ field, term }))
        : { field, term: value };
  });
}

export default {
  includes: ['bigcommerce-spark'],
  ...bigcommerceSparkDefaults,
  Widgets: [
    ...bigcommerceSparkDefaults.Widgets.filter(
      (w) => !['HomeVehicleWidget_custom', 'Garage'].includes(w.name),
    ),
    {
      name: 'CategoryPageSubcategories',
      type: 'FacetTiles',
      location: 'body.page-type-category .themevale_subCategory',
      template: 'facetTiles/subtitles',
      facetField: 'category',
    },
    {
      name: 'RelatedItems',
      location: {
        replace: 'body.page-type-product #productRelated-content',
        class: 'toggle-content is-open',
      },
      template: 'product/relatedItems',
      count: 4,
      getSelection,
    },
    {
      name: 'HeaderVehicleWidget',
      type: 'VehicleWidget',
      location: {
        lastChildOf: '#menu',
        class: 'cm_vehicle-widget__header-container',
      },
      template: 'fitmentSearch/collapsibleVehicleWidget',
      initCollapsed: true,
      isAlwaysColumnLayout: true,
    },
  ],
};
